import cn from "classnames";
import { Heading, HeadingLevel } from "@ariakit/react";

import type { TeaserClusterHeaderItems } from "../helpers/rawGroupToTeaserCluster";
import type { ArticleStyle } from "../../../types/content";

import styles from "./TeaserClusterHeader.module.scss";
import { StoryVignette } from "../../ArticleContent/resources/StoryVignette/StoryVignette";

interface ItemProps {
  text?: string;
  style: ArticleStyle;
}

function Overline({ text, style }: ItemProps) {
  if (!text) {
    return null;
  }

  return <div className={cn([styles.overline, styles[style]])}>{text}</div>;
}

function Title({ text, style }: ItemProps) {
  if (!text) {
    return null;
  }

  return (
    <HeadingLevel>
      <Heading className={cn([styles.title, styles[style]])}>{text}</Heading>
    </HeadingLevel>
  );
}

function Intro({ text, style }: ItemProps) {
  if (!text) {
    return null;
  }

  return <div className={cn([styles.intro, styles[style]])}>{text}</div>;
}

interface TeaserClusterHeaderProps {
  items: TeaserClusterHeaderItems;
  style: ArticleStyle;
  isSmallCluster?: boolean;
  isBox?: boolean;
}

function TeaserClusterHeader({
  items,
  style,
  isSmallCluster,
  isBox,
}: TeaserClusterHeaderProps) {
  if (!Array.isArray(items) || items.length === 0) {
    return null;
  }

  return (
    <div
      className={cn([
        styles.headerContainer,
        styles[style],
        isSmallCluster && styles.smallCluster,
        isBox && styles.box,
      ])}
    >
      {items.map((item, index) => {
        if (item.type === "Overline") {
          return <Overline key={index} text={item.value} style={style} />;
        }
        if (item.type === "Title") {
          return <Title key={index} text={item.value} style={style} />;
        }
        if (item.type === "Intro") {
          return <Intro key={index} text={item.value} style={style} />;
        }
        if (item.type === "StoryVignette") {
          return (
            <StoryVignette
              key={index}
              resource={item}
              isNative={style === "native"}
              isPremium={style === "premium"}
            />
          );
        }
        return null;
      })}
    </div>
  );
}

export { TeaserClusterHeader };
