import { Heading } from "@ariakit/react";
import classNames from "classnames";

import type { Group, Article } from "../../../types/content";
import type { Ad as AdType } from "../../../types/ads/adResource";
import type { ListView } from "../../../types/webTypes";

import { mapTeaserStyle } from "../utils/mapTeaserStyle";
import { CategoryArticlesList } from "./CategoryArticlesList/CategoryArticlesList";
import { TopicArticlesList } from "./TopicArticlesList/TopicArticlesList";
import styles from "../Feed.module.scss";
import { SponsoredBanner } from "../../Ad/SponsoredBanner/SponsoredBanner";

const ListLayout = ({
  data,
  listView,
  isFirstFeed,
  sponsoredBannerAdResource,
  endOfFeed,
  headerTitle,
}: {
  data: Group[];
  listView: ListView;
  isFirstFeed?: boolean;
  sponsoredBannerAdResource?: AdType;
  endOfFeed?: boolean;
  headerTitle?: string;
}) => {
  if (data.length === 0) {
    return null;
  }

  return (
    <div className={listView === "category" ? styles.feed : ""}>
      {isFirstFeed && (
        <>
          <div
            className={classNames([
              styles.listHeader,
              listView === "topic" && styles.listHeaderSmall,
            ])}
          >
            <Heading className={styles.listTitle}>{headerTitle}</Heading>
          </div>
          {sponsoredBannerAdResource?.havePlacementRule && (
            <SponsoredBanner resource={sponsoredBannerAdResource} />
          )}
        </>
      )}
      {data.map((feedGroup, clusterIndex) => {
        if (!feedGroup?.[0]) return null;

        const teaserStyle = mapTeaserStyle(feedGroup);

        if (listView === "topic") {
          const isFirstElement = clusterIndex === 0;
          const shouldPrioritizeImageLoad = clusterIndex > 3;
          return (
            <TopicArticlesList
              feedGroup={feedGroup}
              teaserStyle={teaserStyle}
              shouldPrioritizeImageLoad={shouldPrioritizeImageLoad}
              isFirstElement={isFirstElement}
              key={`${(feedGroup?.[0] as Article)?.article_id}${clusterIndex}`}
              endOfFeed={endOfFeed && clusterIndex === data.length - 1}
              positionInFeed={clusterIndex + 1}
            />
          );
        }

        if (listView === "category") {
          return (
            <CategoryArticlesList
              feedGroup={feedGroup}
              positionInFeed={clusterIndex + 1}
              teaserStyle={teaserStyle}
              key={`${(feedGroup?.[0] as Article)?.article_id}${clusterIndex}`}
            />
          );
        }

        return null;
      })}
    </div>
  );
};

export { ListLayout };
