"use client";

import Link from "next/link";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { usePathname, useSearchParams } from "next/navigation";

import type { ReactNode } from "react";

import { usePulse } from "../../pulse/usePulse";
import styles from "./InfoCard.module.scss";
import { useArticleModalStore } from "../../store/useArticleModalStore";

const InfoCardLink = ({
  slug,
  articleId,
  articleTitle,
  externalLink,
  articleIsPremium,
  children,
}: {
  articleId?: string;
  articleTitle?: string;
  externalLink?: string;
  articleIsPremium?: boolean;
  slug?: string;
  children: ReactNode;
}) => {
  const { ref, inView } = useInView({
    threshold: 2 / 3,
  });
  const { trackInfoCardImpression } = usePulse();
  const { openModal } = useArticleModalStore();
  const pathname = usePathname();
  const searchParams = useSearchParams();

  useEffect(() => {
    if (inView && articleTitle && articleId) {
      trackInfoCardImpression({ articleId, articleTitle, articleIsPremium });
    }
  }, [
    inView,
    articleTitle,
    articleId,
    trackInfoCardImpression,
    articleIsPremium,
  ]);

  return (
    <Link
      href={externalLink ? externalLink : `/${slug}/a/${articleId}`}
      className={styles.link}
      rel={"canonical"}
      ref={ref}
      prefetch={true}
      onClick={() =>
        openModal(
          searchParams.size > 0 ? `${pathname}?${searchParams}` : pathname,
        )
      }
    >
      {children}
    </Link>
  );
};

export { InfoCardLink };
