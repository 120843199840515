"use client";

import type { Group } from "../../../../types/content";

import { Teaser } from "../../../Teaser/Teaser";
import { rawGroupToTeaserCluster } from "../../../TeaserCluster/helpers/rawGroupToTeaserCluster";
import { TeaserCluster } from "../../../TeaserCluster/TeaserCluster";

const CategoryArticlesList = ({
  feedGroup,
  positionInFeed,
  teaserStyle,
}: {
  feedGroup: Group;
  positionInFeed: number;
  teaserStyle: "regular" | "premium" | "native";
}) => {
  let currentNrOfClusters = 0;
  const parsedData = rawGroupToTeaserCluster(feedGroup);

  if (!parsedData) return null;
  currentNrOfClusters++;
  const isSmallCluster =
    parsedData.articles.length === 1 && feedGroup[0]?.type === "Article";
  return (
    <TeaserCluster
      style={teaserStyle}
      header={parsedData.header}
      footer={parsedData.footer}
      key={`${parsedData.name} ${positionInFeed}`}
      isSmallCluster={isSmallCluster}
    >
      {parsedData.articles.map((article, articleIndex) => {
        if (article.type !== "Article") {
          return null;
        }
        const shouldPrioritizeImages = currentNrOfClusters === 1;
        return (
          <Teaser
            article={article}
            hasPriorityImage={shouldPrioritizeImages}
            articlePositionInCluster={articleIndex + 1}
            positionInFeed={positionInFeed}
            key={`${article.article_id} ${positionInFeed}`}
            inCluster
            isBox={teaserStyle === "premium"}
            isNative={teaserStyle === "native"}
          />
        );
      })}
    </TeaserCluster>
  );
};

export { CategoryArticlesList };
