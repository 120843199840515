import type { Group } from "../../../types/content";

import { isArticle, isBox } from "../../../utils/typechecks";

const omitRepeated = (
  prevGroup: Group[],
  nextGroupToAdd: Group[],
  initialItems: Group[],
) => {
  const previousArticles = [...initialItems, ...prevGroup]
    .flat()
    .filter(isArticle);
  const previousBoxes = [...initialItems, ...prevGroup].flat().filter(isBox);

  const filteredArticles = nextGroupToAdd.filter((group) => {
    const groupItem = group.find(
      (groupItem) => isArticle(groupItem) || isBox(groupItem),
    );

    if (!groupItem) {
      return true;
    }

    if (isArticle(groupItem)) {
      return !previousArticles.some(
        (previousArticle) =>
          groupItem.article_id === previousArticle.article_id,
      );
    } else {
      return !previousBoxes.some(
        (previousBox) => groupItem.name === previousBox.name,
      );
    }
  });

  return [...prevGroup, ...filteredArticles];
};

export { omitRepeated };
