import type { Resource } from "../../../types/content";

import { imageSrc } from "../../../utils/image";
import { getStaticMapSrc } from "../../Map/utils/getStaticMapSrc";
import {
  getThumbnailUrl,
  isVideo,
} from "../../ArticleContent/resources/Url/utils";

const getMainMediaUrl = (mainMedia?: Resource) => {
  if (!mainMedia) {
    return;
  }

  if (mainMedia.type === "Image") {
    return imageSrc("small", true, mainMedia);
  }

  if (mainMedia.type === "Map") {
    return getStaticMapSrc(
      {
        width: 320,
        height: 320,
      },
      mainMedia,
    );
  }

  if (mainMedia.type === "Url" && isVideo(mainMedia)) {
    return getThumbnailUrl(mainMedia, "fishstick");
  }

  if (mainMedia.type === "ExternalImage") {
    return `${mainMedia.url}&w=400&h=400`;
  }
};

export { getMainMediaUrl };
