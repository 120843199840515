import cn from "classnames";

import type {
  ReadMoreButtonResource,
  TeaserClusterFooterItems,
} from "../helpers/rawGroupToTeaserCluster";
import type { ArticleStyle } from "../../../types/content";

import styles from "./TeaserClusterFooter.module.scss";
import { Button } from "../../Button/Button";
import { getSlugFromTitle } from "../../../utils/getSlugFromTitle";

const getLink = ({ link }: ReadMoreButtonResource) => {
  if (link.type === "story") {
    return link?.slug
      ? `/t/${link.slug}/${link.topic_id}`
      : `/t/${link.topic_id}`;
  } else if (link.type === "Category") {
    return `/${link.slug}`;
  }
};

function TeaserClusterFooter({
  items,
  style,
  hasBorder,
}: {
  items: TeaserClusterFooterItems;
  style: ArticleStyle;
  hasBorder?: boolean;
}) {
  if (!Array.isArray(items) || items.length === 0) {
    return null;
  }

  return (
    <div
      className={cn([
        styles.footerContainer,
        styles[style],
        !hasBorder && styles.noBorder,
      ])}
    >
      {items.map((resource, index) => {
        if (resource.type === "ReadMoreButton") {
          const link = getLink(resource);

          return (
            <Button
              key={index}
              text={`${resource.value}`}
              size="small"
              variant="secondary"
              type="link"
              href={link}
              isPremium={style === "premium"}
            />
          );
        }
        if (resource.type === "StoryVignette") {
          const title = resource.supertag?.title || resource.title;
          const id = resource.supertag?.topic_id || resource.id;
          const slug = getSlugFromTitle(title);

          return (
            <Button
              key={index}
              text={`Läs mer om ${title}`}
              variant="text"
              size="tiny"
              type="link"
              isPremium={style === "premium"}
              href={`/t/${slug}/${id}`}
            />
          );
        }
        return null;
      })}
    </div>
  );
}

export { TeaserClusterFooter };
