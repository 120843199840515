import cn from "classnames";

import type { Article, Group, StoryVignette } from "../../../../types/content";

import styles from "./TopicArticlesList.module.scss";
import { Teaser } from "../../../Teaser/Teaser";
import { TeaserCluster } from "../../../TeaserCluster/TeaserCluster";

const TopicArticlesList = ({
  feedGroup,
  teaserStyle,
  isFirstElement,
  shouldPrioritizeImageLoad,
  endOfFeed,
  positionInFeed,
}: {
  feedGroup: Group;
  teaserStyle: "regular" | "premium" | "native";
  isFirstElement?: boolean;
  shouldPrioritizeImageLoad?: boolean;
  endOfFeed?: boolean;
  positionInFeed: number;
}) => {
  const firstArticleElement = feedGroup?.[0] as Article;
  const vignette = {
    ...firstArticleElement?.story_vignette,
    type: "StoryVignette",
  } as StoryVignette;

  if (
    !firstArticleElement?.story_vignette?.id &&
    firstArticleElement?.story?.topic_id
  ) {
    vignette.id = firstArticleElement?.story?.topic_id;
  }

  return (
    <div
      className={cn([
        styles.topicArticleContainer,
        isFirstElement && styles.firstArticle,
        endOfFeed && styles.endOfFeed,
      ])}
    >
      <TeaserCluster
        footer={[]}
        header={[vignette]}
        style={teaserStyle}
        hasCardStyling={false}
        isSmallCluster
      >
        {feedGroup.map((group, index) => {
          const articleElement = group as Article;
          return (
            <Teaser
              article={articleElement}
              positionInFeed={positionInFeed}
              articlePositionInCluster={index + 1}
              key={`${articleElement.article_id} ${index}`}
              hasPriorityImage={shouldPrioritizeImageLoad}
              inCluster
              isBox={teaserStyle === "premium"}
              isNative={teaserStyle === "native"}
            />
          );
        })}
      </TeaserCluster>
      {!endOfFeed && <hr className={styles.separator} />}
    </div>
  );
};

export { TopicArticlesList };
